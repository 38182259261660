import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Sidebar from './components/sidebar/Sidebar';
import Navbar from './components/navbar/Navbar';
import Dashboard from './pages/dashboard/Dashboard';
import ApplyForLoan from './pages/applyforloan/ApplyForLoan';
import Disbursement from './pages/disbursement/Disbursement';
import LoginPage from './pages/auth/LoginPage';
import './App.css';
import Branches from './pages/branches/Branches';
import LoanRequests from './pages/loanrequests/LoanRequests';
import Collections from './pages/collections/Collections';
import FieldOfficer from './pages/fieldofficers/FieldOfficers';

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Check if admin_id exists in localStorage
    const adminId = localStorage.getItem('srishti_branch_managerID');
    if (adminId) {
      setIsLoggedIn(true);
    }
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <Router>
      <div className="App">
        {isLoggedIn ? (
          <>
            <Navbar toggleSidebar={toggleSidebar} />
            <div className={`main-container ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
              <Sidebar isSidebarOpen={isSidebarOpen} />
              <div className="content">
                <Routes>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/apply-for-loan" element={<ApplyForLoan />} />
                  <Route path="/disbursement" element={<Disbursement />} />
                  <Route path="/branches" element={<Branches />} />
                  <Route path="/loanrequests" element={<LoanRequests />} />
                  <Route path="/apply-for-loan" element={<ApplyForLoan />} />
                  <Route path="/collections" element={<Collections />} />
                  <Route path="/fieldofficers" element={<FieldOfficer />} />
                  <Route path="*" element={<Navigate to="/dashboard" />} />
                </Routes>
              </div>
            </div>
          </>
        ) : (
          <Routes>
            <Route path="/login" element={<LoginPage setIsLoggedIn={setIsLoggedIn} />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        )}
      </div>
    </Router>
  );
}

export default App;
