import React, { useState, useEffect } from 'react';
import { FaBars } from 'react-icons/fa';
import './Navbar.css';

function Navbar({ toggleSidebar }) {
  const [branchName, setBranchName] = useState('');

  useEffect(() => {
    const fetchBranchData = async () => {
      const branchId = localStorage.getItem('srishti_branch_id');
      if (!branchId) {
        console.error('No branch ID found in local storage');
        return;
      }

      try {
        const response = await fetch(`https://srishticooperativesociety.com/api/branches/${branchId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch branch data');
        }
        const data = await response.json();
        setBranchName(data.branchname);
      } catch (error) {
        console.error('Error fetching branch data:', error);
      }
    };

    fetchBranchData();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('srishti_branch_managerID');
    localStorage.removeItem('srishti_branch_id');
    window.location.reload(); // Refresh the page after logout
  };

  return (
    <div className="navbar">
      <FaBars className="menu-icon" onClick={toggleSidebar} />
      <h1>Shristi Bank - Branch Manager ({branchName})</h1>
      <div className="user-info">
        <button className="logout-button" onClick={handleLogout}>
          Logout
        </button>
      </div>
    </div>
  );
}

export default Navbar;
